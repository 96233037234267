const propertyEventTypes = [
	{ key: 'scope-date-set', status: 'STATUS_PLANNED', order: 1, setsNextDate: true },
	{ key: 'construction-start', status: 'STATUS_INSTALLING', order: 2, setsNextDate: true },
	{ key: 'estimated-complete', status: 'STATUS_INSTALLING', order: 3, setsNextDate: true },
	{ key: 'planned-inspection', status: 'STATUS_INSTALLING', order: 4 },
	{ key: 'ready-for-survey', status: 'STATUS_INSTALLING', order: 5 },
	{ key: 'survey-not-passed', status: 'STATUS_INSTALLING', order: 6 },
	{ key: 'survey-ok', status: 'STATUS_IN_SERVICE', order: 7 },
	{ key: 'inventory', status: 'STATUS_INVENTORY', order: 8 },
	{ key: 'inventory-ok', status: 'STATUS_INVENTORY_DONE', order: 9 },
];

const fastFiberConnectCompanyId = 3560;
const corporateFiberCompanyId = 1045; // [DOMAIN]

// If we are x seconds from token expire, a refresh token will be triggered
const refreshTokenBeforeExpirationInSeconds = 60;

const config = {
	env: process.env.NEXT_PUBLIC_ENV,
	dev: {
		cms: {
			endpoint: 'https://cms.corporatefiber.com', // [DOMAIN]
		},
		backend: {
			endpoint: process.env.BACKEND_ENDPOINT || 'https://api.corporatefiber.com/api', // [DOMAIN]
		},
		graphql: {
			endpoint: process.env.GRAPHQL_ENDPOINT || 'https://api.corporatefiber.com/graphql', // [DOMAIN]
		},
		ispadmin: {
			endpoint: process.env.NEXT_PUBLIC_ISPADMIN_ENDPOINT || 'https://ispadmin-api.corporatefiber.com/api', // [DOMAIN]
		},
		iam: {
			endpoint: process.env.NEXT_PUBLIC_IAM_URL || 'https://iam-dev.corporatefiber.com/api', // [DOMAIN]
			logout: process.env.NEXT_PUBLIC_LOGOUT_REQUEST_URL || 'https://iam.corporatefiber.com/oauth2/logout', // [DOMAIN]
		},
		refreshTokenBeforeExpirationInSeconds,
		propertyEventTypes,
		defaultSLA: '0',
		scrive: {
			endpoint: process.env.SCRIVE_ENDPOINT || 'https://api-testbed.scrive.com/d/',
		},
		fortnox: {
			auth: process.env.NEXT_PUBLIC_FORTNOX_AUTH_URL,
		},
		showSendInviteButton: process.env.SHOW_SEND_INVITE_BUTTON,
		jira: {
			// Allicon Ticker System
			CFTS: process.env.CFTS,
			// ModernLink Helpdesk
			MLCSC: process.env.MLCSC,
		},
	},

	prod: {
		cms: {
			endpoint: 'https://cms.corporatefiber.com', // [DOMAIN]
		},
		backend: {
			endpoint: process.env.BACKEND_ENDPOINT || 'https://api.corporatefiber.com/api', // [DOMAIN]
		},
		graphql: {
			endpoint: process.env.GRAPHQL_ENDPOINT || 'https://api.corporatefiber.com/graphql', // [DOMAIN]
		},
		ispadmin: {
			endpoint: process.env.NEXT_PUBLIC_ISPADMIN_ENDPOINT || 'https://ispadmin-api.corporatefiber.com/api', // [DOMAIN]
		},
		iam: {
			endpoint: process.env.NEXT_PUBLIC_IAM_URL || 'https://iam.corporatefiber.com/api', // [DOMAIN]
			logout: process.env.NEXT_PUBLIC_LOGOUT_REQUEST_URL || 'https://iam.corporatefiber.com/oauth2/logout', // [DOMAIN]
		},
		refreshTokenBeforeExpirationInSeconds,
		propertyEventTypes,
		defaultSLA: '0',
		scrive: {
			endpoint: process.env.SCRIVE_ENDPOINT || 'https://scrive.com/d/',
		},
		fortnox: {
			auth: process.env.NEXT_PUBLIC_FORTNOX_AUTH_URL,
		},
		showSendInviteButton: process.env.SHOW_SEND_INVITE_BUTTON,
		jira: {
			// Allicon Ticker System
			CFTS: process.env.CFTS,
			// ModernLink Helpdesk
			MLCSC: process.env.MLCSC,
		},
	},

	format: {
		date: 'YYYY-MM-DD',
		time: 'HH:mm',
		dateTime: 'YYYY-MM-DD HH:mm',
	},

	signupCompanies: [{ url: 'humlegarden', companyId: 2892, vendorId: corporateFiberCompanyId }], // [DOMAIN]

	corporateFiberCompanyId, // [DOMAIN]

	fastFiberConnectCompanyId,

	contractCancellationNoticePeriodDays: 30,
	contractDueForInvoicingPeriodDays: 20,

	network: {
		grouping: {
			premises: ['outlet', 'device', 'dropAntenna', 'coupler', 'splitter', 'wifiDot', 'tpOutlet'],
		},
	},
};

export default config;

export const getBackendUrl = () => config[process.env.APP_ENVIRONMENT]?.backend.endpoint;
export const getISPAdminUrl = () => config[process.env.APP_ENVIRONMENT]?.ispadmin.endpoint;
