import React, { useEffect } from 'react';
import { Col, message, Row, Button } from 'antd';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import Article from '../components/Article';
import config from '../config';
import jwtDecode from 'jwt-decode';
import useText from 'hooks/useText';
import { useDispatch } from 'react-redux';
import getConfig from 'next/config';

const DEBUG = false;

const Index = () => {
	const theme = getConfig()?.publicRuntimeConfig?.theme;
	const { getText } = useText();
	const dispatch = useDispatch();
	const router = useRouter();
	const authUrl = `${process.env.LOGIN_URL}?client_id=${
		process.env.CLIENT_ID
	}&scope=offline_access&response_type=code&redirect_uri=${encodeURIComponent(
		process.env.LOGIN_REDIRECT_URL,
	)}&epw=hidden&state=${encodeURIComponent(JSON.stringify({ invitation_token: router.query.invite }))}`;
	// If we have a valid session, go to dashboard
	let refreshing;
	useEffect(() => {
		const refresh = async (account) => {
			const url = config[process.env.APP_ENVIRONMENT].iam.endpoint + '/jwt/refresh';
			const { tenantId } = account.user;

			refreshing = message.loading(getText('attempting-to-refresh-session'), 0);

			const refreshTokenRes = await fetch(url, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json', 'X-FusionAuth-TenantId': tenantId },
				body: JSON.stringify({ refreshToken: account.refresh_token }),
			});

			if (!refreshTokenRes.ok) {
				refreshing();
				return false;
			}

			const result = await refreshTokenRes.json();
			DEBUG && console.log('JWT result:', result);
			if (result) {
				account.token = result.token;
				account.tokenPayload = jwtDecode(account.token);
				localStorage.setItem('account', JSON.stringify(account));
				global.reduxStore.dispatch({ type: 'ACCOUNT', payload: account });
				refreshing();
				DEBUG && console.log('Forwarding!');
				await router.push('/page/dashboard', '/page/dashboard');
			} else {
				localStorage.removeItem('account');
				dispatch({ type: 'ACCOUNT', payload: {} });
				refreshing();
			}
		};

		try {
			const account = JSON.parse(localStorage.getItem('account') || '{}');

			if (!account || !account.refresh_token) return;
			const expires = dayjs.unix(account?.tokenPayload?.exp);

			DEBUG && console.log('EXPIRES', expires.format());

			if (expires.isAfter(dayjs())) {
				router.push('/page/dashboard', '/page/dashboard');
				return;
			}

			// Attempt to refresh expired token
			refresh(account); //should we await ???
		} catch (e) {
			console.log('Account Cookie', localStorage.getItem('account'));
			console.log(e);
		}
	}, []);

	return (
		<div className="app">
			<div className="home padded">
				<style jsx>
					{`
						.home {
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;
							height: 100%;
							width: 100%;
							background-color: ${theme['@body-background']};
						}
						.home :global(.ant-row-flex) {
							align-items: stretch;
						}
						.home :global(.article) {
							margin-bottom: 20px;
						}
						.wrap {
							display: inline;
							max-width: 800px;
						}
						.box {
							border-radius: 8px;
							width: 100%;
							height: 100%;
							min-height: 300px;
							overflow: hidden;
							background-color: #ffffff;
						}
						@media (max-width: ${theme['@screen-md']}) {
							.home :global(.article) {
								margin-bottom: 0;
							}
							.box {
								min-height: 250px;
							}
						}
						.ant-btn-primary {
							background-color: ${theme['@text-green']};
						}
					`}
				</style>

				<div className="wrap">
					<Row align="middle">
						<Col xs={{ span: 24 }} md={{ span: 24 }}>
							<Article slug="login-page" />
							<Button
								type="primary"
								onClick={() => {
									router.push(authUrl);
								}}
							>
								{getText('login.loginByBankIDButton')}
							</Button>
						</Col>
					</Row>
				</div>
			</div>
		</div>
	);
};

export default Index;
export { getStaticProps } from 'lib/getStaticProps';
