import React from 'react';
import Markdown from 'markdown-to-jsx';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Alert } from 'antd';
import { useRouter } from 'next/router';

const Article = (props) => {
	const articles = useSelector((redux) => redux.articles);
	const router = useRouter();
	const language = router.locale;
	const { slug } = props;

	let article;
	if (articles instanceof Array) {
		article = articles.find((a) => a.slug === slug);
		if (!article)
			return (
				<div>
					<Alert message={`CMS Article ${slug} not available.`} showIcon type="warning" />
				</div>
			);
		article.header = article['header_' + language];
		article.content = article['content_' + language];
	} else {
		article = {
			header: '<<<' + slug + '>>>',
			content: 'Article not found.',
		};
	}

	return (
		<div className="articles">
			<h1 className="heading">{article.header}</h1>
			<div className="card">
				<Markdown>{article.content}</Markdown>
			</div>
		</div>
	);
};

Article.propTypes = {
	slug: PropTypes.string.isRequired,
};

export default Article;
